import { useState, useRef, useEffect, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import secure from "../../../../utils/secure.js";
import {
  getApp,
  getStudioUsers,
  getStudioUsersBySubject,
} from "../../../../utils/rest.js";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "./../../../../ui-component/cards/MainCard";
import Transitions from "./../../../../ui-component/extended/Transitions";

// assets
import { IconLogout, IconSettings, IconWallet } from "@tabler/icons";
import blancImage from "../../../../assets/images/appBlanc.png";

// ==============================|| PROFILE MENU ||============================== //
import Auth from "./../../../../utils/auth.js";

import languagesLabels from "./../../../../languages/languages";

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDashboard = location.pathname === "/";
  const user = useRef(Auth.getUser());

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [appIcon, setAppIcon] = useState(blancImage);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(user.current?.role);
  const [selectedUser, setSelectedUser] = useState(user.current?._id);
  const [selectRoleOpen, setSelectRoleOpen] = useState(false);
  const [selectUserOpen, setSelectUserOpen] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    customization.language
  );

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  useEffect(() => {
    if (selectedRole !== user.current?.role) {
      window.location.reload();
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectedUser !== user.current?._id) {
      navigate("/app-sections", { state: location.state });
      window.location.reload();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (isDashboard) {
      setLoading(false);
      setAppIcon(blancImage);
    } else if (
      location &&
      location.state &&
      location.state.app &&
      location.state.app._id
    ) {
      setAppIcon(location.state.app.icon);
    } else {
      setLoading(false);
      setAppIcon(blancImage);
    }
  }, [location]);

  useEffect(() => {}, [customization]);

  async function onGetUsers(idSubject) {
    let token = await Auth.getToken();
    try {
      setLoading(true);
      let encrypted = secure.encrypt({ idSubject, token });
      let res = await axios.get(getStudioUsersBySubject, {
        params: { appsisto: encrypted },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Credentials": true,
        },
      });
      const sortedUsers = res.data.users.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      setUsers(sortedUsers);

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  function renderText(key) {
    let value = languagesLabels.signin[key];
    let label = value.find((l) => l.language === selectedLanguage);
    return label.label;
  }
  const handleLogout = async () => {
    Auth.signout();
    navigate("/login");
  };
  const handleSettings = async () => {
    let user = await Auth.getUser();
    navigate("/profile", { state: { user, app: location.state.app } });
    setOpen(false);
  };

  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setSelectedRole(newRole);

    let user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      user.role = newRole;
      localStorage.setItem("user", JSON.stringify(user));
    }
  };
  const handleUserChange = (event) => {
    const newUser = event.target.value;
    setSelectedUser(newUser);

    let user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      user.email = newUser.email;
      user.name = newUser.name;
      user.role = newUser.role;
      user.surname = newUser.surname;
      user._id = newUser._id;
      localStorage.setItem("user", JSON.stringify(user));
    }
  };

  const handleSelectRoleOpen = () => {
    setSelectRoleOpen(true);
  };

  const handleSelectRoleClose = () => {
    setSelectRoleOpen(false);
  };
  const handleSelectUserOpen = () => {
    setSelectUserOpen(true);
  };

  const handleSelectUserClose = () => {
    setSelectUserOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (!selectRoleOpen && !selectUserOpen) {
      setOpen(false);
    }
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };

  const handleToggle = async () => {
    setOpen((prevOpen) => !prevOpen);
    try {
      await onGetUsers(location.state.app.subject);
    } catch (error) {
      console.log(error);
    }
  };

  const prevOpen = useRef(open);
  // useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  function renderSelectRole() {
    return (
      <Grid item>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
          <InputLabel htmlFor="outlined-adornment-role"></InputLabel>
          <Select
            id="outlined-adornment-role"
            type="role"
            value={selectedRole}
            name="role"
            onChange={handleRoleChange}
            label="Role"
            inputProps={{}}
            sx={{}}
            open={selectRoleOpen}
            onOpen={handleSelectRoleOpen}
            onClose={handleSelectRoleClose}
          >
            <MenuItem value={"read_only"}>read only</MenuItem>
            <MenuItem value={"starter"}>starter</MenuItem>
            <MenuItem value={"sender"}>sender</MenuItem>
            <MenuItem value={"service"}>service</MenuItem>
            <MenuItem value={"manager"}>manager</MenuItem>
            <MenuItem value={"user"}>user</MenuItem>
            <MenuItem value={"admin"}>admin</MenuItem>
            <MenuItem value={"root"}>root</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }

  function renderSelectUser() {
    return (
      <Grid item>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
          <InputLabel htmlFor="outlined-adornment-user"></InputLabel>
          <Select
            id="outlined-adornment-user"
            type="user"
            value={selectedUser}
            name="user"
            onChange={handleUserChange}
            label="User"
            inputProps={{}}
            sx={{}}
            open={selectUserOpen}
            onOpen={handleSelectUserOpen}
            onClose={handleSelectUserClose}
          >
            {users.map((user, index) => (
              <MenuItem key={index} value={user}>
                {user.name + " " + user.surname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        onClick={handleToggle}
        sx={{ maxWidth: "300px", cursor: "pointer" }}
      >
        {!isMobile && (
          <Grid item>
            <Typography
              ref={anchorRef}
              sx={{
                color: theme.palette.text.primary,
              }}
            >
              {Auth.getFullName() + " (" + Auth.getRole() + ")"}
            </Typography>
          </Grid>
        )}
        <Grid item>
          {loading ? (
            <CircularProgress size={30} sx={{ marginLeft: 1 }} />
          ) : (
            <Avatar
              sx={{
                width: 30,
                height: 30,
                marginLeft: 1,
                bgcolor: "white",
                color: theme.palette.text.primary,
                borderRadius: "50%",
              }}
              src={appIcon}
            />
          )}
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [30, 30],
              },
            },
          ],
        }}
        style={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={160}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <PerfectScrollbar
                    style={{
                      zIndex: 9999999999,
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 250,
                          minWidth: 200,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        {user.current?.realRole === "root" &&
                          renderSelectRole()}
                        {user.current?.realRole === "root" &&
                          !isDashboard &&
                          renderSelectUser()}
                        {location.pathname.startsWith("/app-sections") && (
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                            }}
                            selected={selectedIndex === 0}
                            onClick={handleSettings}
                          >
                            <ListItemIcon>
                              <IconSettings stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: theme.palette.text.primary }}
                                >
                                  {renderText("accountSettings")}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )}

                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{ color: theme.palette.text.primary }}
                              >
                                {renderText("signout")}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
