import Cookies from "js-cookie";
const Auth = {
  authenticate(_id, name, surname, email, role, subject, realRole, access) {
    localStorage.setItem(
      "user",
      JSON.stringify({
        _id,
        name,
        surname,
        email,
        role,
        subject,
        realRole,
      })
    );
  },
  signin(token) {
    localStorage.setItem("token", token);
  },
  signout() {
    localStorage.removeItem("user");
    Cookies.remove("token");
    localStorage.removeItem("token");
  },
  getUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user;
    else return null;
  },
  getFullName() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user.name + " " + user.surname;
    else return "";
  },
  getName() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user.name;
    else return "";
  },
  getSurname() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user.surname;
    else return "";
  },
  getEmail() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user.email;
    else return "";
  },
  getRole() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user.role;
    else return "";
  },
  getSubject() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user.subject;
    else return "";
  },

  getId() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) return user._id;
    else return "";
  },
  isAuth() {
    /*if (Cookies.get("token") !== undefined) return true;
    else return false;*/
    if (localStorage.getItem("user")) return true;
    else return false;
  },
  getToken() {
    let token = localStorage.getItem("token");
    if (token) return token;
    else return null;
  },
};

export default Auth;
