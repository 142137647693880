import { useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Divider,
  Box,
  ListItemIcon,
  ListItemButton,
  FormControlLabel,
  Switch,
  Grid,
  ButtonBase,
  Avatar,
} from "@mui/material";
import { IconLanguage, IconMenu2, IconSettings } from "@tabler/icons";
import Breadcrumbs from "./Breadcrumbs";
import Auth from "../../../utils/auth";
import ProfileSection from "../Header/ProfileSection";
import WalletSection from "../Header/WalletSection";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import languagesLabels from "./../../../languages/languages";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { TOGGLE_THEME } from "../../../store/actions";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "native-base";
import { Menu } from "@mui/icons-material";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const SmartHeader = ({ drawerOpen, drawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const user = useRef(Auth.getUser());
  const isDashboard = !location.pathname.startsWith("/app-sections");

  function renderText(key) {
    let value = languagesLabels.smartHeader[key];
    let label = value.find((l) => l.language === customization.language);
    return label.label;
  }

  const handleSettings = () => {
    navigate("/profile", {
      state: { user, app: location.state.app },
    });
  };

  const handleThemeChange = (event) => {
    dispatch({ type: TOGGLE_THEME });
  };

  function renderToggler() {
    if (user.current) {
      return (
        <ButtonBase
          sx={{
            borderRadius: "0px",
            overflow: "hidden",
            right: 5,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: "#4cb85c",
              color: "#ffffff",
              "&:hover": {
                background: "#4cb85c",
                color: "#ffffff",
              },
            }}
            onClick={drawerToggle}
            color="inherit"
          >
            <Menu />
          </Avatar>
        </ButtonBase>
      );
    } else {
      return [];
    }
  }

  function renderWallet() {
    if (location.state.app.useParo || location.state.app.useSmsNotifications) {
      return (
        <>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: theme.palette.text.default,
              margin: 1,
            }}
          />

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <WalletSection />
          </Box>
        </>
      );
    }
  }

  return (
    <Grid container sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: "10px",
            borderColor: theme.palette.borderColor.default,
            borderStyle: theme.palette.borderStyle,
            borderWidth: "2px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Toolbar
            sx={{
              maxHeight: "40px",
              padding: "0 12px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              [theme.breakpoints.up("sm")]: {
                paddingLeft: "10px",
                paddingRight: "10px",
              },
            }}
          >
            {!drawerOpen && renderToggler()}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Breadcrumbs theme={theme.palette} />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={customization.theme === "dark"}
                    onChange={handleThemeChange}
                  />
                }
              />
              <ListItemButton color="inherit" disabled>
                <ListItemIcon>
                  <IconLanguage size="1.5rem" />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton
                color="inherit"
                onClick={handleSettings}
                disabled={isDashboard}
              >
                <ListItemIcon>
                  <IconSettings size="1.5rem" />
                </ListItemIcon>
              </ListItemButton>
              {!isDashboard && renderWallet()}
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: theme.palette.text.default, margin: 1 }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ProfileSection />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Grid>
    </Grid>
  );
};

export default SmartHeader;
